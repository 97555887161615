.title {
  min-width: 150px;
  max-width: 250px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.address,
.workHour {
  min-width: 200px;
  max-width: 410px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.selectType {
  max-width: 200px;
  width: 100%;
}