.modal {
  width: 600px !important;
  top: 50px;

  &.sm {
    width: 800px !important;
  }
  &.md {
    width: 1000px !important;
  }
  &.lg {
    width: 1200px !important;
  }

  :global {
    .ant-modal-body {
      height: 75vh;
      overflow: auto;
  
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      & > div:first-child {
        padding: 0px 10px 0px 5px;
      }
    }
  }
}
