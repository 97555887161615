.tables {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > h2 {
    margin-bottom: -20px;
  }
}

.tableContent {
  & > h1,
  & > h2,
  & > h3,
  & > h4 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: #154284;
    margin: 10px 0px;

    & > * {
      color: #154284 !important;
    }
  }

  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
  color: black;

  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: black;

    & > a {
      text-decoration: underline;
      overflow-wrap: anywhere;
    }

    & > strong {
      font-weight: 600;
    }
  }

  & > h1 + p,
  & > h2 + p,
  & > h3 + p,
  & > h4 + p {
    margin-top: 11px;
  }

  & > ul,
  & > ol {
    margin: 0 auto;
    padding: 0 19px;
    font-size: 18px;
    font-weight: 400;

    & > li {
      margin: 12px 0 12px 16px;
    }
  }

  & > ul li {
    list-style-type: disc;
  }
}

.tableContent {
  & > table {
    border-spacing: 0;
    border-collapse: collapse;
    display: grid;
    overflow-x: auto;

    tr {
      border-bottom: 1px solid #efefef;
      font-size: 14px;

      & > td {
        padding: 16px 24px;
        line-height: normal;
      }

      &:nth-child(odd) {
        background-color: #f8f8f8;
      }

      &:first-child {
        background-color: black;
        color: white;
        font-weight: 500;

        & > td,
        & > th {
          text-align: left;
          line-height: normal;
          padding: 4px 24px;
          font-weight: 500;
        }
      }
    }
  }
}

.label {
  margin-left: 5px;
}