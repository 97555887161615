html,
body {
  scroll-behavior: smooth;
  width: 100vw;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: Montserrat Medium !important;
}

button {
  cursor: pointer;
  background: none;
}

textarea {
  resize: none;
}

ul,
li {
  list-style: none;
}
/* font */
@font-face {
  font-family: Montserrat;
  src: local("Montserrat"),
    url("../fonts/Montserrat-250.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Montserrat Medium;
  src: local("Montserrat Medium"),
    url("../fonts/Montserrat-400.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Montserrat Bold;
  src: local("Montserrat Bold"),
    url("../fonts/Montserrat-600.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Montserrat Italic;
  src: local("Montserrat Italic"),
    url("../fonts/Montserrat-italic-600.ttf") format("truetype");
  font-weight: 500;
}

/* ant */
.ant-layout .ant-layout-sider-children {
  background: #001529;
  padding-top: 28%;
}

.ant-menu-dark.ant-menu-root.ant-menu-inline {
  background: #001529;
}

/* .ant-layout-sider ant-layout-sider-dark{
  width: 330px !important;
} */

.ant-layout .ant-layout-sider-trigger {
  background: #001529;
}

.ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  background: #001529;
}

.ant-menu-submenu ant-menu-submenu-inline .ant-menu-submenu:active {
  background-color: #2b265c;
}

.ant-menu-item .ant-menu-item-selected {
  background-color: #2b265c;
}

.ant-menu-item .ant-menu-iten-active::before {
  background-color: grey;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: grey;
}

.ant-layout .ant-layout-sider-trigger {
  border-top: 11px grey;
}

.ant-menu {
  background: none !important;
}
.ant-menu-title-content:hover {
  color: white;
}
.ant-menu-submenu
  .ant-menu-submenu-vertical
  .ant-menu-submenu-open
  .ant-menu-submenu-active {
  background-color: black;
}
/* .ant-menu.ant-menu-inline .ant-menu-item{
  position: relative;
  margin-right: 200px;


} */

.ant-menu-dark .ant-menu-item-selected {
  background-color: #761a4c;
}
/* .ant-layout ant-layout-has-sider {
  min-height: 100vh;
  max-height: 200vh;
} */
