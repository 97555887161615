.list {
  display: flex;
  flex-direction: column;
  gap: 6px;

  & > li {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  }

  & .text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    padding: 5px 10px;
    border: 1px solid rgb(235, 233, 233);
    border-radius: 10px;
    overflow: hidden;
  }
}

.title,
.desc {
  cursor: pointer;
  min-width: 150px;
  max-width: 400px;
  border: 1px solid rgb(235, 233, 233);
  border-radius: 10px;
  padding: 5px 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  &:hover {
    background-color: rgba(226, 226, 226, 0.1);
  }
}

.desc {
  max-width: 500px;
  -webkit-line-clamp: 4;
}
