.header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
  }
  
  .selectType {
    max-width: 240px;
    width: 100%;
  }
  