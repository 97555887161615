.orderNumber {
  min-width: 150px;
}

.price {
  min-width: 120px;
  text-align: center;
  padding-right: 20px;
}

.center {
  text-align: center;
}
