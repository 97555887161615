.label {
  font-size: 1rem;
  font-weight: 500;
}

.formItem {
  :global {
    .ant-form-item-has-error {
      & .quill {
        border: 1px solid #ff4d4f;
      }
    }
  }
}

.editor {
  :global {
    .ql-container {
      height: 200px;
    }
  }
}
