.title {
  min-width: 150px;
  max-width: 320px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.desc {
  min-width: 300px;
  max-width: 600px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.img {
  width: 100px !important;
  max-height: 100px !important;
  object-fit: contain;
}

.header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.selectType {
  max-width: 270px;
  width: 100%;
}
