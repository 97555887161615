.header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.select {
  max-width: 150px;
  width: 100%;
}
