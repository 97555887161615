.busy {
  min-width: 150px;
}

.experience {
  min-width: 100px;
}

.city {
  min-width: 100px;
}

.salary {
  max-width: 200px;
}
