.header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.selectType {
  max-width: 270px;
  width: 100%;
}

.letter {
  cursor: pointer;
}

.name {
  min-width: 140px;
}

.date {
  min-width: 100px;
}

.formVacancy {
  & * {
    color: rgba(0, 0, 0, 0.88) !important;
  }
}
