.videos {
  display: flex;
  gap: 10px;
  padding: 15px 0px;
  overflow: auto;
}

.videoOne {
  width: 33.3%;
  min-width: 300px;

  &:hover .select {
    box-shadow: 0px 5px 20px 0px rgb(0, 0, 0, 0.2);
  }
}

.videoBlock {
  position: relative;
  display: block;
  padding-top: 56.5%;
}

.video {
  position: absolute;
  display: block;
  object-fit: contain;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100% !important;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.select {
  display: flex;
  height: 80px;

  :global {
    .ant-select-selector {
      border-radius: 0px !important;
      border-color: rgba(172, 172, 172, 0.2) !important;
      box-shadow: none !important;
    }
  }
}

.btnDelete {
  margin-left: 10px;
}
