.block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.videoBlock {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  overflow: hidden;
}

.videoPlayer {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn {
  width: fit-content;
}

.desc {
  font-size: small !important;
}
