/* EditableTable.css */

.editable-table-container {
  margin: 10px 0;
}

.add-column-btn,
.add-row-btn,
.send-html-btn {
  margin-right: 10px;
}

.btns-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-column-btn {
  margin-bottom: 10px;
}

.delete-column-btn {
  border: none;
  margin-left: 8px;
}

.editable-table {
  width: 100%;
  border-collapse: collapse;
}

.editable-table th,
.editable-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.editable-table th {
  background-color: #f2f2f2;
}

.editable-cell {
  border: none;
  width: 100%;
  padding: 1px;
}

/* Additional custom styles can be added as needed */
