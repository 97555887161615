.sider {
  :global {
    .ant-layout-sider-children {
      padding-top: 10%;
    }
  }
}

.siderBlock {
  padding-top: 10%;
  height: 89vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #07192b;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4984bb;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #145ca0;
  }
}

.admins {
  cursor: pointer;
  color: white;
  padding: 0px 20px;
  margin: 20px 0px;
}
